import React from 'react'

const Navigations = () => {
  const navlinks = [
    {
      link: 'all',
      name: 'All News'
    },
    {
      link: 'stocks',
      name: 'Stocks'
    },
    {
      link: 'crypto',
      name: 'Crypto'
    },
    {
      link: 'forex',
      name: 'Forex'
    },
    {
      link: 'futures',
      name: 'Futures'
    },
    {
      link: 'bonds',
      name: 'Bonds'
    },
    {
      link: 'world-economy',
      name: 'World Economy'
    },
  ]
  return (
    <>
      <div>
      <ul className='grid grid-cols-2 md:flex justify-center gap-3 md:gap-x-4 py-10'>
          {navlinks.map((nav, index) => {
            return (
              <li key={index} className='hover:bg-primaryGreen hover:text-white duration-700 font-semibold bg-[#E1FFF5] py-2 md:px-4 text-center text-black rounded-full'>
                <a href={nav.link}>{nav.name}</a>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  )
}

export default Navigations