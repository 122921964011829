import React from 'react'
import '../App.css'

import blacklogo from '../assets/images/blacklogo.svg'
import { ForgotPasswordForm } from '../components/Forms'

const ForgotPassword = () => {
    return (
        <>
            <div className='auth_bg py-20 px-5 md:px-0 flex justify-center'>
                <div className='w-full md:w-[35%] flex flex-col justify-center'>
                    <div className='flex justify-center py-5'>
                        <img src={blacklogo} alt='black_logo' />
                    </div>
                    <div>
                        <div className='bg-[#E6FEF6] rounded-lg border border-[#0E9F6E] p-5'>
                            <div>
                                <p className='font-bold text-2xl uppercase my-2'>Forgot your password?</p>
                                <p className='leading-relaxed'>No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.</p>
                            </div>
                            <ForgotPasswordForm/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword