import React from 'react'
import img1 from '../../../assets/images/newsimg.svg'

export const NewsCards = ({ text,date }) => {
    return (
        <>
            <div className='text-white hover:text-black flex flex-col gap-y-2 font-semibold hover:bg-[#E1FFF5] p-4 rounded-2xl cursor-pointer duration-700'>
                <p>{text}</p>
                {/* <img src={img} alt='image_one' className='w-[100px] h-[100px] rounded-md' /> */}
                <p className=' text-left text-xs text-slate-300 italize'>{date}</p>
            </div>
        </>
    )
}


export const TopNewsCard = () => {
    return (
        <>
            <div className='gap-x-5 font-semibold hover:bg-[#E1FFF5] p-4 rounded-2xl cursor-pointer duration-700'>
                <img src={img1} alt='image_one' className='w-[200px] h-[200px] rounded-md' />
                <p>MSFT: Microsoft’s 2030 Sales Target Revealed to be $550bn</p>
            </div>
        </>
    )
}


