import React from 'react'
import cancelbtn from '../assets/images/cancelbtn.svg'
import { useNavigate } from 'react-router-dom'

const TermsOfUse = () => {
    const navigate = useNavigate()
    const goBack = () => {
        navigate(-1)
    }
    return (
        <>
            <div className='relative'>
                <div className='w-full justify-center text-center py-[20px]'>
                    <p className='font-bold text-xl'>Terms and Conditions of Use</p>
                </div>
                <div className='absolute top-1 right-2'>
                    <img src={cancelbtn} alt='closePage' className='cursor-pointer' onClick={goBack} />
                </div>

                <div className='px-10 flex flex-col gap-y-4'>
                    <p>Welcome to MarketsMind.ai (the "Website"), operated by MarketsMind, Inc. ("MarketsMind," "we," "us," or "our"). By accessing or using the Website, you acknowledge and agree to comply with these Terms and Conditions of Use ("Terms"). These Terms govern your use of the Website, including any services, features, content, or applications offered by MarketsMind through the Website. Please read these Terms carefully before using the Website.</p>

                    <p>
                        1. <span className='font-semibold'>Acceptance of Terms</span>
                        <br />1.1 By accessing or using the Website, you agree to be bound by these Terms. If you do not agree with these Terms, please refrain from accessing or using the Website.
                        <br />1.2 We reserve the right to modify, amend, or update these Terms at any time without prior notice. Any changes to these Terms will be effective immediately upon posting on the Website. It is your responsibility to review these Terms periodically for any updates. Continued use of the Website after any modifications constitute your acceptance of the revised Terms.
                    </p>

                    <p>
                        2. <span className='font-semibold'>Use of Services</span>
                        <br />2.1 Eligibility: You must be at least 18 years old to use MarketsMind.ai. By accessing or using our services, you represent and warrant that you are of legal age to enter into this agreement.
                        <br />2.2 Compliance: You agree to comply with all applicable laws and regulations while using our website and services.
                    </p>

                    <p>
                        3. <span className='font-semibold'>Intellectual Property</span>
                        <br />3.1 Ownership: All intellectual property rights, including but not limited to copyrights, trademarks, trade names, patents, and any other proprietary rights, associated with the Website and its content are the sole property of MarketsMind or its licensors.
                        <br />3.2 Limited License: You may use the Website and its content for personal, non-commercial purposes only. You are not granted any rights to modify, reproduce, distribute, display, perform, transmit, publish, or create derivative works based on any part of the Website without our prior written consent.
                    </p>

                    <p>
                        3. <span className='font-semibold'>User Conduct</span>
                        <br />3.1 Prohibited Activities: You agree not to engage in any activities that could harm or interfere with the proper functioning of MarketsMind.ai or its users. Prohibited activities include but are not limited to:
                        <div className='px-4'>
                            a. Uploading or transmitting any harmful, unlawful, or offensive content.
                            <br />b. Attempting to gain unauthorized access to our systems or other users' accounts.
                            <br />c. Using our platform for any illegal or unauthorized purposes.
                            <br />d. Impersonating another person or entity.
                        </div>
                    </p>

                    <p>
                        4. <span className='font-semibold'>Links to Third-Party Websites</span>
                        <br />4.1 The Website may contain links to third-party websites or resources that are not owned or controlled by MarketsMind. We do not endorse or assume any responsibility for the content, policies, or practices of any third-party websites. Accessing and using such third-party websites is at your own risk.
                        <br />4.2 You acknowledge and agree that MarketsMind shall not be liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any content, products, or services available on or through any third-party websites.
                    </p>

                    <p>
                        5. <span className='font-semibold'>Information Disclaimer</span>
                        <br />5.1 The information provided on the Website is for general informational purposes only and should not be construed as professional advice or guidance. MarketsMind makes no representations or warranties of any kind, express or implied, regarding the accuracy, reliability, suitability, or completeness of any information on the Website.
                        <br />5.2 Any reliance you place on such information is at your own risk. MarketsMind shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with the use or misuse of any information obtained from the Website.
                    </p>

                    <p>
                        6. <span className='font-semibold'>Subscriptions and Purchases</span>
                        <br />6.1 MarketsMind may offer subscriptions or products for purchase on the Website. By subscribing or making a purchase, you agree to pay the applicable fees and comply with any additional terms and conditions specific to the subscription or purchase.
                        <br />6.2 Subscriptions: If you choose to subscribe to any services offered on the Website, you acknowledge and agree that your subscription will be billed in advance on a recurring basis. The billing cycle will be specified at the time of purchase. You authorize us to charge your chosen payment method automatically for the applicable subscription fees until you cancel your subscription.
                        <br />6.3 Cancellation: You have the right to cancel your subscription at any time. To cancel, you must follow the cancellation procedures provided on the Website or contact our customer support. Please note that cancelling your subscription does not entitle you to a refund for any unused portion of the subscription period.
                    </p>

                    <p>
                        7. <span className='font-semibold'>Limitation of Liability</span>
                        <br />7.1 To the fullest extent permitted by law, MarketsMind.ai and its affiliates, officers, directors, employees, and agents shall not be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with your use or inability to use our platform.
                        <br />7.2 In jurisdictions where limitations of liability are not permitted, our liability shall be limited to the extent permitted by law.
                    </p>

                    <p>
                        8. <span className='font-semibold'>Indemnification</span>
                        <br />8.1 You agree to indemnify, defend, and hold MarketsMind and its officers, directors, employees, agents, and affiliates harmless from and against any claims, losses, damages, liabilities, costs, or expenses arising out of or relating to your use of the Website or any violation of these Terms.
                    </p>

                    <p>
                        9. <span className='font-semibold'>Termination</span>
                        <br />9.1 MarketsMind reserves the right to terminate, suspend, or restrict your access to the Website, in whole or in part, at any time and for any reason without prior notice.
                    </p>

                    <p>
                        10. <span className='font-semibold'>Governing Law and Jurisdiction</span>
                        <br />10.1 These Terms shall be governed by and construed in accordance with the laws of the Federal Republic of Nigeria. Any legal action or proceeding arising out of or relating to these Terms shall be exclusively brought in the courts of the Federal Republic of Nigeria, and you consent to the personal jurisdiction of such courts.
                    </p>

                    <p>
                        11. <span className='font-semibold'>Miscellaneous</span>
                        <br />11.1 These Terms constitute the entire agreement between you and MarketsMind regarding your use of the Website and supersede any prior or contemporaneous agreements, communications, or proposals, whether oral or written, between you and MarketsMind.
                        <br />11.2 If any provision of these Terms is deemed invalid or unenforceable, that provision shall be deemed severable and shall not affect the validity or enforceability of the remaining provisions.
                        <br />11.3 MarketsMind's failure to enforce any right or provision under these Terms shall not constitute a waiver of such right or provision.
                    </p>

                    If you have any questions or concerns about these Terms, please contact us at<br />
                    info@marketsmind.ai<br />
                    Last updated: 24th July,2023
                </div>
            </div>
        </>
    )
}

export default TermsOfUse