import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

import cancelbtn from '../../../assets/images/cancelbtn.svg'

const NewsPage = () => {
    const { id } = useParams();
    const [newsItem, setNewsItem] = useState(null);

    useEffect(() => {
        fetchNewsItem();
    }, [id]);

    const fetchNewsItem = () => {
        axios
            .get(`https://mm-newsapi-29137cf47886.herokuapp.com/api/get-details/?docID=${id}`)
            .then((response) => {
                setNewsItem(response.data);
                console.log(response.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };
    console.log(`STATE: ${newsItem}`)

    return (
        <>
            <div className='md:pt-[90px] pb-10 md:py-0 px-5 md:px-20 flex flex-col gap-y-4'>
                <div className='bg-[#E8FFF7] py-4'>
                    <p className='font-semibold text-[30px]'>News</p>
                </div>
                <div className='w-full flex justify-end'>
                    <Link to={'/news'}>
                        <img src={cancelbtn} alt='cancel-button' />
                    </Link>
                </div>
                {newsItem && (
                    <div className='text-black'>
                        <p className='font-bold text-3xl md:text-[40px] py-8 md:py-0'>{newsItem.Story.Headline}</p>
                        <div dangerouslySetInnerHTML={{ __html: newsItem.Story.Body }} />
                    </div>
                )}

            </div>
        </>
    );

}

export default NewsPage