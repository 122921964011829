import React, { useState } from 'react'
import '../App.css'
import dropdownicon from '../assets/images/dropdownicon.svg'
import dropdowniconblack from '../assets/images/dropdowniconblack.svg'
import brk1 from '../assets/images/fxtm.svg'
import point from '../assets/images/pointicon.svg'
import { motion } from 'framer-motion';
import ReviewModal from '../components/modals/ReviewModal'

const Brokers = () => {

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [openReview, setOpenReview] = useState(false)

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
  };

  const openReviewHandler = () => {
    setOpenReview(!openReview)
    console.log(openReview)
  }

  // const handleClose = () => {
  //   setOpenModal(!openModal)
  //   console.log(openModal)
  // }

  return (
    <>
      <div className='bg-black relative'>
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
        >
          <div className='brokerheroimg bg-[black] py-40 px-5 md:px-20 h-[500px] flex items-center'>
            <div className='w-full flex justify-center items-center'>
              <div className='flex flex-col gap-y-4'>
                <p className='text-[#FACA15] font-bold text-[60px] md:text-[100px] pt-10 text-center'>Brokers</p>
                <p className='text-white font-bold text-[20px] md:text-[30px] text-center'>Find the right broker and invest on your own</p>
                <div className='flex justify-center'>
                  <div className='w-[70%] flex flex-col gap-y-4'>
                    <div className="flex flex-col gap-y-2 text-sm">
                      <label className='text-[white]'>Please select your country</label>
                      <div>
                        <div className='border border-[grey] rounded-full py-3 flex justify-between px-4' onClick={toggleDropdown}>
                          <p className='text-sm text-[white]'>{selectedOption ? selectedOption : 'Select country'}</p>
                          <img src={dropdownicon} alt='dropdown_icon' className='w-[15px]' />
                        </div>
                        {isDropdownOpen && (
                          <ul className="dropdown-options bg-[white] rounded-md">
                            <li className='py-2 px-2 hover:bg-[gray] rounded-md' onClick={() => handleOptionSelect('UAE')}>UAE</li>
                            <li className='py-2 px-2 hover:bg-[gray] rounded-md' onClick={() => handleOptionSelect('United States')}>United States</li>
                            <li className='py-2 px-2 hover:bg-[gray] rounded-md' onClick={() => handleOptionSelect('Nigeria')}>Nigeria</li>
                          </ul>
                        )}
                      </div>
                    </div>
                    <div className='flex flex-col gap-y-2'>
                      <label className='text-white text-sm'>Search by name</label>
                      <input placeholder='Type broker name' className='bg-[transparent] placeholder-[white] border border-[grey] py-3 rounded-full px-4 text-sm' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='px-5 md:px-20 py-2 md:py-10 bg-black '>
            <p className='text-white text-center font-bold text-2xl'>Recommended Broker</p>
            <div className='flex gap-x-10 justify-center'>
              <div className='w-full 2xl:w-[80%] flex flex-col md:flex-row gap-y-10 md:gap-x-10 md:py-10 py-5'>
                <div className='flex justify-center items-center w-full h-[200px] md:w-[30%] bg-[#3f4140] rounded-xl'>
                  <div className='text-center flex flex-col justify-center py-4 gap-y-2'>
                    <div className='flex justify-center'>
                      <img src={brk1} alt='broker_img' className='h-[50px]' />
                    </div>
                    <div className='flex flex-col justify-center items-center gap-y-3 py-2'>
                      <button className='bg-primaryYellow rounded-full py-2 px-4 text-white flex items-center justify-between w-full md:w-full text-sm'>Create Account <img src={point} alt='point-icon' /></button>
                    </div>
                  </div>
                </div>
                <div className='w-full md:w-[70%] min-h-[200px] md:min-h-[initial] bg-[#3f4140] p-2 rounded-xl'>
                  <div>
                    <div className='flex items-center gap-x-2 pb-2' style={{ borderBottom: '2px solid white' }}>
                      <img src={dropdowniconblack} alt='dropdown-icon' />
                      <p className='font-bold text-2xl text-white'>Key information</p>
                    </div>
                    <div>
                      <ol className='list-disc flex flex-col text-white gap-y-2 px-10 py-5'>
                        <li><span className='font-semibold underline'>Assets Traded:</span> Forex, Crypto, Stocks</li>
                        <li><span className='font-semibold underline'>Withdrawal Method:</span> Kenyan/Local Transfers Credit Cards E-Wallets Bankwire </li>
                        <li><span className='font-semibold underline'>Deposit Method:</span> Kenyan/Local Transfers Credit Cards E-Wallets Bankwire </li>
                        <li><span className='font-semibold underline'>Minimum Deposit:</span> $100 </li>
                        <li><span className='font-semibold underline'>Commission:</span> 0.4¢ - $2 Commission on FX and CFDS, 0.0 Spreads close to zero on major FX pairs, including EURUSD and USDJPY, and as low as zero on Gold, XAUUSD, 0% commission on US stocks </li>
                      </ol>
                    </div>
                  </div>
                  <div>
                    <div className='flex items-center gap-x-2 pb-2'>
                      <div className='flex cursor-pointer gap-x-2' onClick={openReviewHandler}>
                        <img src={dropdowniconblack} alt='dropdown-icon' />
                        <p className='font-bold text-2xl text-white'>Read review</p>
                      </div>
                    </div>
                    {openReview && <div className='py-5 px-5 flex flex-col gap-y-2 text-white'>
                      <p>FXTM, also known as ForexTime, is a prominent global forex broker founded in 2011. The company operates under the regulatory oversight of esteemed authorities, including the Cyprus Securities and Exchange Commission (CySEC), the Financial Conduct Authority (FCA) in the UK, the Financial Sector Conduct Authority (FSCA) in South Africa, and the Financial Services Commission (FSC) in Mauritius. As a comprehensive brokerage, FXTM provides access to a diverse selection of over 250 trading instruments, spanning forex, CFDs, stocks, commodities, indices, and cryptocurrencies.
                        One of FXTM's key strengths lies in its competitive and low spreads, starting from 0.1 pips for the Advantage account (ECN) and 1.5 pips for the Advantage Plus account. The broker also prides itself on fast execution speeds, with an average of 0.15 seconds for ECN orders and 0.3 seconds for other account types. To accommodate various trading preferences, FXTM supports both the widely trusted MetaTrader 4 and MetaTrader 5 platforms. Additionally, they offer the FXTM Trader mobile app, facilitating trading on the go from smartphones and tablets.
                        FXTM understands the significance of education in successful trading, and as such, they offer an extensive array of educational resources. Clients can access webinars, seminars, articles, videos, ebooks, and a comprehensive forex glossary. Moreover, FXTM boasts a dedicated team of market analysts who provide daily market commentary, technical analysis, and trading signals to help traders make informed decisions
                        Furthermore, FXTM has designed various account types tailored to meet diverse trading needs. However, it is essential to note that some account types have higher minimum deposit requirements. For example, the Advantage account necessitates a minimum deposit of $200, while the Advantage MT5 account requires a minimum deposit of $500 for stock trading.
                        While FXTM offers numerous advantages, including their strong regulatory compliance, wide range of trading instruments, competitive spreads, and educational resources, it is crucial to be aware of certain limitations. Notably, the unavailability of their services in certain regions such as the US, Canada, Japan, Israel, Iran, North Korea, and other countries due to regulatory constraints. Prospective clients should carefully evaluate their eligibility and preferences before considering FXTM as their broker of choice.In conclusion, FXTM stands as a reputable and reliable forex broker, presenting traders with a robust platform and an extensive selection of financial instruments. The broker's commitment to regulation and educational resources makes it an appealing option for many traders. However, the suitability of FXTM for individual traders may vary depending on factors such as location and account requirements. Therefore, conducting thorough research and comparing various brokers is essential before finalizing a decision.</p>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='flex gap-x-10 bg-[#f9f9f9]'>
            
            <div className='w-[30%] bg-[white] py-10 flex flex-col gap-y-5'>
              <div className='flex flex-col gap-y-1'>
                <p className='font-semibold'>Possible to deposit with</p>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="checkbox"
                      value="option1"
                    />
                    Bank transfer
                  </label>
                </div>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="checkbox"
                      value="option1"
                    />
                    Credit/debit card
                  </label>
                </div>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="checkbox"
                      value="option1"
                    />
                    E-wallet
                  </label>
                </div>
              </div>

              <div className='flex flex-col gap-y-1'>
                <p className='font-semibold'>Broker features</p>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="checkbox"
                      value="option1"
                    />
                    Investor protection
                  </label>
                </div>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="checkbox"
                      value="option1"
                    />
                    Quality mobile platform
                  </label>
                </div>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="checkbox"
                      value="option1"
                    />
                    Award Winning
                  </label>
                </div>
              </div>

              <div className='flex flex-col gap-y-1'>
                <p className='font-semibold'>Assets important to you</p>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="checkbox"
                      value="option1"
                    />
                    Forex (e.g. EURUSD)
                  </label>
                </div>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="checkbox"
                      value="option1"
                    />
                    Stocks and ETFs (e.g. Apple)
                  </label>
                </div>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="checkbox"
                      value="option1"
                    />
                    Cryptos (e.g. Bitcoin)
                  </label>
                </div>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="checkbox"
                      value="option1"
                    />
                    Others
                  </label>
                </div>
              </div>

              <div className='flex flex-col gap-y-1'>
                <p className='font-semibold'>How much money will you be depositing?</p>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="radio"
                      value="option1"
                    />
                    Less than $50
                  </label>
                </div>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="radio"
                      value="option1"
                    />
                    $50 - $100
                  </label>
                </div>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="radio"
                      value="option1"
                    />
                    $101 - $200
                  </label>
                </div>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="radio"
                      value="option1"
                    />
                    Greater than $200
                  </label>
                </div>
              </div>

              <div className='flex flex-col gap-y-1'>
                <p className='font-semibold'>Trading platform</p>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="checkbox"
                      value="option1"
                    />
                    Metatrader 4
                  </label>
                </div>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="checkbox"
                      value="option1"
                    />
                    Metatrader 5
                  </label>
                </div>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="checkbox"
                      value="option1"
                    />
                    Broker's platform
                  </label>
                </div>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="checkbox"
                      value="option1"
                    />
                    Others
                  </label>
                </div>
              </div>

              <div className='flex flex-col gap-y-1'>
                <p className='font-semibold'>Options for withdrawal</p>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="checkbox"
                      value="option1"
                    />
                    Crypto
                  </label>
                </div>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="checkbox"
                      value="option1"
                    />
                    Bank transfer
                  </label>
                </div>
                <div>
                  <label className='flex gap-x-2 items-center '>
                    <input
                      type="checkbox"
                      value="option1"
                    />
                    Perfect Money
                  </label>
                </div>
              </div>

            </div>
            <div className='w-[70%] py-10 flex flex-col gap-y-3'>
              <p className='text-center'>Filter and compare brokers to find the one best suited for you!</p>
              <div className='flex justify-between w-full bg-[#f9fffb] items-center px-2 py-2'>
                <img src={backarrow} alt='back_arrow' />
                <div className='flex w-full'>
                  <div className='w-[30%]'>
                    <div className='text-center flex flex-col justify-center py-4 gap-y-2'>
                      <div className='flex justify-center'>
                        <img src={brk1} alt='broker_img' className='h-[50px]' />
                      </div>
                      <p className='font-bold text-lg'>XTB</p>
                      <div className='flex flex-col justify-center items-center gap-y-3'>
                        <button className='bg-primaryYellow rounded-full py-2 px-4 text-white flex items-center justify-between w-[80%] text-sm'>Read review <img src={point} alt='point-icon' /></button>
                        <button className='border border-primaryGreen rounded-full py-2 px-4 text-black flex items-center justify-between w-[80%] text-sm'>Create account <img src={pointblack} alt='point-icon' /></button>
                      </div>
                    </div>
                  </div>
                  <div className='w-[30%]'>
                  <div className='text-center flex flex-col justify-center py-4 gap-y-2'>
                    <div className='flex justify-center'>
                      <img src={brk2} alt='broker_img' className='h-[50px]' />
                    </div>
                    <p className='font-bold text-lg'>Octafx</p>
                    <div className='flex flex-col justify-center items-center gap-y-3'>
                      <button className='bg-primaryYellow rounded-full py-2 px-4 text-white flex items-center justify-between w-[80%] text-sm'>Read review <img src={point} alt='point-icon'/></button>
                      <button className='border border-primaryGreen rounded-full py-2 px-4 text-black flex items-center justify-between w-[80%] text-sm'>Create account <img src={pointblack} alt='point-icon'/></button>
                    </div>
                  </div>
                </div><div className='w-[30%]'>
                  <div className='text-center flex flex-col justify-center py-4 gap-y-2'>
                    <div className='flex justify-center'>
                      <img src={brk3} alt='broker_img' className=' h-[50px]' />
                    </div>
                    <p className='font-bold text-lg'>Capital.com</p>
                    <div className='flex flex-col justify-center items-center gap-y-3'>
                      <button className='bg-primaryYellow rounded-full py-2 px-4 text-white flex items-center justify-between w-[80%] text-sm'>Read review <img src={point} alt='point-icon'/></button>
                      <button className='border border-primaryGreen rounded-full py-2 px-4 text-black flex items-center justify-between w-[80%] text-sm'>Create account <img src={pointblack} alt='point-icon'/></button>
                    </div>
                  </div>
                </div>
                </div>
                <img src={frontarrow} alt='front_arrow' />
              </div>
              <div>
                <div className='flex items-center gap-x-2 pb-2' style={{borderBottom: '2px solid black'}}>
                  <img src={dropdowniconblack} alt='dropdown-icon'/>
                  <p className='font-bold text-2xl'>Key information</p>
                </div>
              </div>
            </div>
          </div> */}
          </div>
        </motion.div>
        {/* {openModal &&
          <div className='fixed z-50 inset-0 bg-black bg-opacity-50 flex items-center justify-center w-full h-full'>
            <div className='backdrop-blur-md w-[98%] md:w-[80%]'>
              <ReviewModal handleClose={handleClose} />
            </div>
          </div>
        } */}
      </div>
    </>
  )
}

export default Brokers