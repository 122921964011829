import React from 'react'
import { YellowButton } from '../../Buttons'
import brokerslist from '../../../assets/images/brokerslist.svg'

const CompareBrokerSection = () => {
    return (
        <>
            <div className='flex 3xl:w-[80%] flex-col md:flex-row items-center gap-y-5 md:gap-y-0 gap-x-20'>

                <div className='w-full md:w-[50%] flex flex-col justify-center md:justify-end text-center md:text-start gap-y-4'>
                    <p className='font-bold text-2xl md:text-[50px] leading-10 text-primaryGreen'>Compare Brokers</p>
                    <p>Choosing an online broker and doing brokerage comparison can be challenging due to the many options available. Finding the right trading platform can be difficult, especially for beginners, so we’ve make it easier for you!</p>
                    <div><YellowButton buttonText={'Signup Now'} /></div>
                </div>
                <div className='w-full md:w-[50%] 3xl:flex justify-end'>
                    <img src={brokerslist} alt='compare brokers' />
                </div>
            </div>
        </>
    )
}

export default CompareBrokerSection