import React from 'react'
import { YellowButton } from '../../Buttons'
import contactus from '../../../assets/images/contactus.svg'
import teamimg from '../../../assets/images/team.svg'


const ContactUsSection = () => {
    return (
        <>
            <div className='flex 3xl:w-[80%] flex-col-reverse md:flex-row items-center gap-y-5 md:gap-y-0 gap-x-20'>
                <div className='w-full md:w-[50%]  flex justify-end rounded-md'>
                    <img src={teamimg} alt='contact us' className='rounded-xl w-full h-full object-cover'/>
                </div>
                <div className='w-full md:w-[50%] flex flex-col justify-center md:justify-start text-center md:text-start gap-y-4'>
                    <p className='font-bold text-2xl md:text-[50px] text-primaryGreen'>Contact Us</p>
                    <p>Our dedicated team of top-notch broker experts and analysts has global expertise covering all industry areas ranging from stocks and commodities to forex and cryptocurrency trading.

                        <br/><br/>We are committed to improving our services, and we appreciate all feedback. We welcome your questions or comments at</p>
                        <div><YellowButton buttonText={'Info@marketsmind.com'}/></div>
                </div>
            </div>
        </>
    )
}

export default ContactUsSection