import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { NewsCards } from '../News/NewsCards'
const NewsSection = () => {
  const [news, setNews] = useState(null)
  useEffect(() => {
    fetchNews()
  }, [setNews])

  const fetchNews = () => {
    axios
      .get('https://mm-newsapi-29137cf47886.herokuapp.com/api/news/')
      .then((response) => {
        console.log(`RESPONSE: ${response.data.MarketsNewsOutput.Stories}`);
        setNews(response.data.MarketsNewsOutput.Stories)
      })
      .catch((error) => {
        console.error(error);
      });
  };


  return (
    <>
      <div className='py-10 gap-y-10'>
        <h2 className='font-bold text-[35px] text-white'>News</h2>
        <div className='grid md:grid-cols-2 gap-4 '>
          {news !== null && news.slice(0, 10).map((story, index) => {
            return (
              <div key={story.DocumentID}>
                <Link to={`/news/${story.DocumentID}`}>
                  <NewsCards text={story.Headline} date={story.TimeStampRAW} />
                </Link>
              </div>
            );
          })}

        </div>
        <div className='flex justify-end w-full text-white '>
          <Link to={'/news'}><p style={{ borderBottom: '1px solid #FDAD00' }}>See More</p></Link>
        </div>
      </div>
      <div className='w-full bg-black'>
      </div>
    </>
  )
}

export default NewsSection