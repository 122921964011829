import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/marketsmind_logo.svg'
import facebook from '../assets/images/Facebook.svg'
import twitter from '../assets/images/Twitter.svg'
import instagram from '../assets/images/Instagram.svg'
import youtube from '../assets/images/YouTube.svg'


const SiteFooter = () => {
  return (
    <>
      <div className='bg-[#262626] w-full px-20 py-16'>
        <div className='text-[white] flex flex-col gap-y-3 md:flex-row justify-between'>
          <div><Link to={'/'}><img src={logo} alt='marketsmind_logo' /></Link></div>
          <div className='text-center md:text-justify'>
            <ul className='flex flex-col gap-y-4 md:gap-y-2'>
              <Link to={'/about-us'}>
              <li>About Us</li>
              </Link>
             <Link to={'/contact'}>
             <li>Contact Us</li>
             </Link>
            </ul>
          </div>
          <div className='text-center md:text-justify'>
            <ul className='flex flex-col gap-y-4 md:gap-y-2'>
              <Link to={'/privacy-policy'}>
              <li>Privacy Policy</li>
              </Link>
              <Link to={'/terms-of-use'}>
              <li>Terms of Use</li>
              </Link>
            </ul>
          </div>
          <div className='flex flex-col gap-y-3 text-center md:text-justify'>
            <p className='font-bold'>CONTACT US</p>
            <ul className='flex flex-col gap-y-4 md:gap-y-2'>
              <li>info@marketsmind.Ai</li>
            </ul>
          </div>
          <div className='flex flex-col gap-y-4 md:gap-y-2 text-center md:text-justify'>
            <p className='font-bold'>FOLLOW US</p>
            <ul className='flex justify-center md:justify-start'>
              <li><img src={facebook} alt='facebook'/></li>
              <li><img src={twitter} alt='twitter'/></li>
              <li><img src={instagram} alt='instagram'/></li>
              <li><img src={youtube} alt='youtube'/></li>
            </ul>
            <ul className='flex flex-col gap-y-4 md:gap-y-2'>
              <li>All Rights Reserved</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default SiteFooter

