import { Routes, Route } from "react-router-dom"
import './App.css';
import ScrollToTop from './components/ScrollToTop';
import Home from "./pages/Home";
import Brokers from "./pages/Brokers";
import News from "./pages/News";
import AiAnalyst from "./pages/AiAnalyst";
import About from './pages/About'
import Contact from './pages/Contact'
import Login from './pages/Login'
import SignUp from './pages/SignUp'
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import NewsPage from "./components/sections/News/NewsPage";
import DarkLayout from "./components/layouts/DarkLayout";
import GreenLayout from "./components/layouts/GreenLayout";
import ChatLayout from "./components/layouts/ChatLayout";
import { AnimatePresence } from "framer-motion";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";

import ScrollContextProvider from './ScrollContext';

function App() {
  return (
    <>
      <div>
        <ScrollContextProvider>
          <ScrollToTop />
          <AnimatePresence>
            <Routes>
              <Route path="/" element={<DarkLayout><Home /></DarkLayout>} />
              <Route path="/brokers" element={<DarkLayout><Brokers /></DarkLayout>} />
              <Route path="/news" element={<GreenLayout><News /></GreenLayout>} />
              <Route path="/ai-analyst" element={<ChatLayout><AiAnalyst /></ChatLayout>} />
              <Route path="/about-us" element={<DarkLayout><About /></DarkLayout>} />
              <Route path="/contact" element={<DarkLayout><Contact /></DarkLayout>} />
              <Route path="/login" element={<GreenLayout><Login /></GreenLayout>} />
              <Route path="/sign-up" element={<GreenLayout><SignUp /></GreenLayout>} />
              <Route path="/forgot-password" element={<GreenLayout><ForgotPassword /></GreenLayout>} />
              <Route path="/reset-password" element={<GreenLayout><ResetPassword /></GreenLayout>} />
              <Route path='/news/:id' element={<NewsPage />} />
              <Route path='/privacy-policy' element={<PrivacyPolicy />} />
              <Route path='/terms-of-use' element={<TermsOfUse />} />
            </Routes>
          </AnimatePresence>
        </ScrollContextProvider>
      </div>
    </>
  );
}

export default App;
