import React, { useEffect } from 'react';

const EconomicCalender = () => {
  useEffect(() => {
    const scriptId = 'tradingview-widget-script';
    let script = document.getElementById(scriptId);
    if (!script) {
      script = document.createElement('script');
      script.id = scriptId;
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-events.js';
      script.async = true;
      script.innerHTML = JSON.stringify({
        colorTheme: 'dark',
        isTransparent: true,
        width: '100%',
        height: '600',
        locale: 'en',
        importanceFilter: '-1,0,1',
        "currencyFilter": "USD,EUR,ITL,NZD,CHF,AUD,FRF,JPY,ZAR,TRL,CAD,DEM,MXN,ESP,GBP"
      });
      document.querySelector('.economic-calendar-container').appendChild(script);
    }
  }, []);

  return (
    <div className="tradingview-widget-container">
      <div className=' bg-[black] w-[100%] h-[600px]'>
        <div className="economic-calendar-container"></div>
      </div>
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
          {/* <span className="blue-text">Track all markets on TradingView</span> */}
        </a>
      </div>
    </div>
  );
};

export default EconomicCalender;
