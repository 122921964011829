import React from 'react'
import '../App.css'
import { YellowButton } from '../components/Buttons'

import founding from '../assets/images/founding_story.png'
import vision from '../assets/images/vision.png'
import { motion } from 'framer-motion'


const About = () => {
  return (
    <>
      <div className='bg-black'>
        {/* ---------SECTION 1 ---------------- */}
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
        >
          <div className='3xl:flex justify-center'>
            <div className='3xl:w-[80%]'>
              <div className='aboutImage  py-20 px-5 md:px-20 md:h-[500px] flex items-center'>
                <div className='mt-[20px] w-full md:w-[40%]'>
                  <p className='text-primaryGreen font-bold text-4xl md:text-5xl'>About Marketsmind.ai</p>
                  <p className='text-[#FFFFFF] pt-2'>
                    Welcome to MarketsMind.ai, your ultimate destination for comprehensive market analysis, cutting-edge financial market news, and top-notch education for both aspiring and seasoned financial professionals. At MarketsMind.ai, we believe in harnessing the power of artificial intelligence to provide unparalleled insights and guidance to navigate the ever-changing landscape of the financial markets.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* -----------SECTION 2----------------- */}
          <div className='3xl:flex justify-center'>
            <div className='3xl:w-[80%]'>
              <div className='flex 2xl:justify-between flex-col-reverse md:flex-row gap-y-10 md:gap-y-0 gap-x-10 py-10 px-5 md:px-20 bg-black text-white'>
                <div>
                  <img src={founding} alt='help_image_section' className='rounded-2xl' />
                </div>
                <div className='w-full md:w-[50%]  flex items-center'>
                  <div className='flex flex-col gap-y-5 md:gap-y-0'>
                    <p className='text-primaryGreen font-bold text-center text-justify text-4xl md:text-5xl tracking-tighter leading-[50px]'>Our Founding Story</p>
                    <p className='text-justify pt-2'>
                      MarketsMind.ai was founded with a singular vision - to become the leading source of market intelligence and knowledge, empowering individuals and institutions worldwide to make informed decisions and achieve financial success. Our founders recognized the need for a platform that seamlessly combines data-driven analysis, real-time market updates, and expert insights to support investors, traders, and financial enthusiasts alike.
                      With a combined experience of over 20 years in the financial markets, our team of experts has seen firsthand the challenges faced by professionals in this dynamic industry. This collective knowledge forms the backbone of MarketsMind.ai, ensuring that we deliver reliable and cutting-edge solutions to our valued users.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ------------SECTION 3---------------------- */}

          <div className='3xl:flex justify-center'>
            <div className='3xl:w-[80%]'>
              <div className='bg-black flex gap-x-10 justify-center items-center md:justify-start py-10 px-5 md:px-20 '>
                <div className='w-full md:w-[50%] flex 2xl:justify-between flex-col justify-center md:justify-start gap-y-10'>
                  <p className='text-primaryGreen text-3xl text-center text-justify text-4xl md:text-5xl font-bold tracking-tighter leading-[50px]'>Our Vision: Empowering Global Emergence</p>
                  <img src={vision} alt='methodology_image' className='block md:hidden' />
                  <p className='text-[white] text-justify'>
                    At MarketsMind.ai, we envision a world where financial market participants from every corner of the globe are empowered with the knowledge and tools they need to flourish. We aspire to be the driving force behind this global emergence, bridging the gap between information and action, and fostering financial literacy that knows no borders.
                    We envision a future where individuals, no matter their background or location, can confidently access our AI-powered market analysis, stay ahead of the curve with up-to-the-minute financial news, and enhance their expertise through our specialized educational resources.
                  </p>
                  <div>
                    <YellowButton buttonText={'Compare Now'} />
                  </div>
                </div>
                <div className='hidden md:block w-[50%] h-[500px] 3xl:h-[600px]'>
                  <img src={vision} alt='methodology_image' className='w-full h-full object-contain' />
                </div>
              </div>
            </div>
          </div>

          {/* -----------SECTION 4----------------- */}
          <div className='3xl:flex justify-center'>
            <div className='3xl:w-[80%]'>
              <div className='flex 2xl:justify-between flex-col-reverse md:flex-row gap-y-10 md:gap-y-0 gap-x-10 py-10 px-5 md:px-20 bg-black text-white'>
                {/* <div>
                  <img src={helpimg} alt='help_image_section' className='rounded-2xl' />
                </div> */}
                <div className='w-full bg-[#3f4140] flex items-center p-8 rounded-xl'>
                  <div className='flex flex-col gap-y-10 md:gap-y-0'>
                    <p className='text-primaryGreen font-bold text-justify text-4xl md:text-5xl tracking-tighter leading-[50px]'>Our Mission: Navigating Your Financial Success</p>
                    <p className='text-justify pt-2'>
                      Our mission at MarketsMind.ai is clear - to tailor our cutting-edge solutions and resources to guide you towards financial success in an ever-evolving marketplace. We are committed to providing:
                    </p>
                    <div className='pt-2'>
                      <ol className='list-decimal px-6 flex flex-col gap-y-4'>
                        <li><span style={{ borderBottom: '1px solid white' }}>Unrivaled Market Analysis</span>: Our inbuilt AI technology enables us to process vast amounts of market data, allowing us to deliver accurate and actionable insights that will give you a competitive edge.</li>
                        <li><span style={{ borderBottom: '1px solid white' }}>Real-time Financial Market News</span>:  Stay informed and make informed decisions with our up-to-the-minute financial news updates, covering global markets, emerging trends, and critical events that can impact your investments.</li>
                        <li><span style={{ borderBottom: '1px solid white' }}>Education for All</span>: We believe in democratizing financial knowledge. Our comprehensive educational materials, courses, and tutorials cater to both newcomers and experienced professionals, empowering you to take control of your financial future.</li>
                        <li><span style={{ borderBottom: '1px solid white' }}>Personalized Guidance</span>: Each investor's journey is unique. That's why we aim to provide personalized guidance, understanding your needs and aspirations to deliver tailored recommendations that align with your financial goals.</li>
                        <li><span style={{ borderBottom: '1px solid white' }}>Global Community</span>: Join a diverse community of financial enthusiasts, investors, and experts, where knowledge is shared, insights are exchanged, and connections are made.</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

      </div>
    </>
  )
}

export default About