import React from 'react'
import { useNavigate } from 'react-router-dom'
import cancelbtn from '../assets/images/cancelbtn.svg'

const PrivacyPolicy = () => {
    const navigate = useNavigate()
    const goBack = () => {
        navigate(-1)
    }
    return (
        <div className='px-10 py-5'>
            <div className='flex gap-x-5 items-center py-4'>
                <div>
                    <img src={cancelbtn} alt='close-page' onClick={goBack} className='cursor-pointer'/>
                </div>
                <p className='font-semibold text-lg'>Privacy Policy - MarketsMind.ai</p>
            </div>
            <div className='flex flex-col gap-y-2'>
                <p>Effective Date: 1st August 2023</p>

                <p>At MarketsMind.ai, we are committed to safeguarding your privacy and protecting your personal information.</p>
                <p>This Privacy Policy outlines how we collect, use, disclose, and manage your data when you interact with our website and services. By using MarketsMind.ai, you consent to the practices described in this policy.</p>
            </div>
            <ol className='list-decimal flex flex-col gap-y-4 px-4'>
                <li>
                    Information We Collect:
                    We may collect certain information from you when you visit our website, subscribe to our services, or interact with our platform. The types of information we collect include but are not limited to:
                    <br />- Personal Information: Name, email address, contact details, and any other information you provide voluntarily.
                    <br />- User Activity Data: We may collect data on your interactions with our website, such as pages visited, time spent on the website, and other analytical data.
                    <br />- Cookies and Similar Technologies: We may use cookies and similar technologies to enhance your user experience and collect data about your usage patterns.
                </li>

                <li>
                    How We Use Your Information:
                    We use the collected information for the following purposes:
                    <br />- Providing and Improving Services: To deliver the services you request and continually enhance our platform's functionality and user experience.
                    <br />- Communication: To send you relevant updates, newsletters, and marketing communications related to our services, provided you have given us consent.
                    <br />- Market Analysis: To aggregate and anonymize data for market analysis and research purposes.
                </li>

                <li>
                    Data Security:
                    We prioritize the security of your information and take reasonable measures to protect it from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
                </li>
                <li>
                    Data Disclosure:
                    We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties unless we provide you with advance notice. However, we may share your data with trusted third parties who assist us in operating our website, conducting our business, or providing services to you, as long as those parties agree to keep this information confidential.
                </li>

                <li>
                    Legal Compliance:
                    We may disclose your information if required to do so by law or in response to a valid legal request by public authorities.
                </li>

                <li>
                    Third-Party Links:
                    Our website may contain links to third-party websites or services. Please note that we are not responsible for the privacy practices of such sites or services. We encourage you to review the privacy policies of those third parties before interacting with them.
                </li>

                <li>
                    Children's Privacy:
                    Our services are not directed to individuals under the age of 18. We do not knowingly collect personal information from children. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us, and we will promptly delete such information from our records.
                </li>

                <li>
                    Your Choices:
                    You have the right to access, correct, or delete the personal information we hold about you. If you wish to exercise these rights or have any questions about your data, please contact us using the information provided below.
                </li>

                <li>
                    Changes to this Privacy Policy:
                    We reserve the right to modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated policy on our website.
                </li>
            </ol>
        </div>
    )
}

export default PrivacyPolicy