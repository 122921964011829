import React from 'react'
import '../App.css'

import blacklogo from '../assets/images/marketsmind_logo.svg'
import backgroundImg from '../assets/images/bars.png'
import { LoginForm } from '../components/Forms'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

const Login = () => {
  return (
    <>
      <div className='bg-black'>
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
        >
          <div className=' py-20 px-5 md:px-0 flex justify-center relative bg-black'>
            <div className='h-[80%] w-[50%] absolute left-0 top-36'>
              <img src={backgroundImg} alt='background-images' className='h-full w-full' />
            </div>
            <div className='w-full md:w-[35%] flex flex-col justify-center z-20'>
              <div className='flex justify-center py-5'>
                <img src={blacklogo} alt='black_logo' />
              </div>
              <div>
                <div className='bg-[#E6FEF6] rounded-lg border border-[#0E9F6E] p-5'>
                  <LoginForm />
                  <p className='text-center'>You don't have an account? <Link to={'/sign-up'}><span className='font-bold'>Sign up</span></Link></p>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  )
}

export default Login