import React from 'react'

import { YellowButton } from '../components/Buttons'
import contactus from '../assets/images/contactus.svg'
import { ContactForm } from '../components/Forms'

import ca from '../assets/images/Ellipse1.svg'
import cb from '../assets/images/Ellipse3.svg'
import cc from '../assets/images/Ellipse4.svg'
import { motion } from 'framer-motion'

const Contact = () => {
  return (
    <>
      <div className='bg-black'>
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
        >
          <div className='px-5 md:px-20 pt-20 md:pt-40 bg-[black]'>
            <div className='2xl:flex justify-center'>
              <div className='flex 2xl:w-[80%] flex-col-reverse md:flex-row items-center gap-y-5 md:gap-y-0 gap-x-20 py-10'>
                <div className='w-full md:w-[50%]'>
                  <img src={contactus} alt='contact us' className='rounded-xl' />
                </div>
                <div className='w-full md:w-[50%] flex flex-col justify-center md:justify-start text-center md:text-start gap-y-4'>
                  <p className='font-bold text-4xl md:text-5xl text-primaryGreen mb-5 '>Contact Us</p>
                  <p className='text-white'>Our dedicated team of top-notch broker experts and analysts has global expertise covering all industry areas ranging from stocks and commodities to forex and cryptocurrency trading.<br /><br />
                    They work tirelessly every day to give you the most comprehensive and up-to-date information on brokerages so you can pick the one that best meets your needs.<br /><br />
                    We are committed to improving our services, and we appreciate all feedback.<br /><br />
                    We welcome your questions or comments at</p>
                  <div><YellowButton buttonText={'Info@marketsmind.com'} className={'text-white'} /></div>
                </div>
              </div>
            </div>
            <div className='2xl:flex justify-center'>
              <div className='2xl:w-[80%] pb-10'>
                <div className='bg-[#0E9F6E] bg-opacity-25 px-5 md:px-20 py-10 rounded-md relative overflow-hidden'>
                  <img src={ca} alt='curve_one' className='absolute right-[-100px] top-0 h-[240px]' />
                  <img src={cb} alt='curve_two' className='absolute right-[-80px] top-80' />
                  <img src={cc} alt='curve_three' className='absolute left-[-120px] bottom-[-150px]' />
                  <p className='text-[40px] font-bold text-white'>Email us directly</p>
                  <ContactForm />
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  )
}

export default Contact