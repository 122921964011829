import React from 'react'
import Navigations from '../Navigations'
import SiteFooter from '../SiteFooter'

const DarkLayout = ({ children }) => {
  return (
    <>
        <Navigations/>
        <main>
            {children}
        </main>
        <SiteFooter/>
    </>
  )
}

export default DarkLayout