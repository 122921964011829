import React, { useEffect } from 'react';
import Header from '../components/sections/News/Header';
import NewsSection from '../components/sections/News/NewsSection';
import TickerTape from '../components/TickerTape';
import EconomicCalender from '../components/EconomicCalender';
import { motion } from 'framer-motion';
import { useScrollContext } from '../ScrollContext';

const News = () => {
  const { scrollToEconCalender } = useScrollContext();

  useEffect(() => {
    // Check if the hash in the URL is 'economic-calender'
    setTimeout(() => {
      if (window.location.hash === '#economic-calender') {
        // Scroll to the EconomicCalender section when the component mounts
        const econCalendarSection = document.getElementById('econs');
        if (econCalendarSection) {
          econCalendarSection.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, 2000);
  }, []);
  return (

    <>
      <div className='bg-black'>
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
        >
          <div className='pt-[65px] md:pt-[90px] bg-black pb-5'>
            <div className='bg-black w-full fixed'>
              <TickerTape />
            </div>
            <Header />
            <NewsSection />

            <div className='px-5 md:px-20'>
              <div id='econs'></div>
              <EconomicCalender />

            </div>

          </div>
        </motion.div>
      </div>
    </>
  );
};

export default News;
