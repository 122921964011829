import React, { useEffect } from 'react';

const TradingNewsCards = () => {
  useEffect(() => {
    const scriptId = 'tradingview-widget-script';
    let script = document.getElementById(scriptId);
    if (!script) {
      script = document.createElement('script');
      script.id = scriptId;
      script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-timeline.js';
      script.async = true;
      script.innerHTML = JSON.stringify({
        feedMode: 'all_symbols',
        colorTheme: 'dark',
        isTransparent: true,
        displayMode: 'compact',
        width: '100%',
        height: '400',
        locale: 'en',
      });
      document.querySelector('.tradingview-widget-container__widget').appendChild(script);
    }
  }, []);

  return (
    <div className="tradingview-widget-container">
      <style>
        {`
          /* Hide the TradingView snaps header */
          .tv-snaps-header {
            display: none;
          }
        `}
      </style>
      <div className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright">
        <a href="https://www.tradingview.com/" rel="noopener nofollow" target="_blank">
          {/* <span className="blue-text">Track all markets on TradingView</span> */}
        </a>
      </div>
    </div>
  );
};

export default TradingNewsCards;
