import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { NewsCards } from './NewsCards'

const NewsSection = () => {
  const [news, setNews] = useState(null)
  useEffect(() => {
    fetchNews()
    // fetchNewsa()
  },[setNews])

  const fetchNews = () => {
    axios
      .get('https://mm-newsapi-29137cf47886.herokuapp.com/api/news/')
      .then((response) => {
        console.log(`RESPONSE: ${response.data.MarketsNewsOutput.Stories}`);
        setNews(response.data.MarketsNewsOutput.Stories)
      })
      .catch((error) => {
        console.error(error);
      });
  };
 

  return (
    <>
      <div className='px-5 md:px-20 py-10 gap-y-10'>
        <h2 className='font-bold text-[35px] text-white'>Top Stories</h2>
        <div className='grid md:grid-cols-3 gap-4 '>
          {news !== null && news.map((story, index) => {
            return (
              <div key={story.DocumentID}>
                <Link to={`/news/${story.DocumentID}`}>
                  <NewsCards text={story.Headline} date={story.TimeStampRAW} />
                </Link>
              </div>
            );
          })}
        </div>
        <div className='py-8'>
          {/* <button className='w-full hover:bg-slate-50 capitalize font-semibold text-lg border-2 border-[#3f4140] text-white hover:text-black rounded-full py-2 px-4'>Read more news</button> */}
        </div>
      </div>
      <div className='w-full bg-black'>
      </div>
    </>
  )
}

export default NewsSection