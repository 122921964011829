import React from 'react'
import aichat from '../assets/images/aichat.svg'
// import LiveUpdatesSection from '../components/sections/Home/LiveUpdatesSection'
import ContactUsSection from '../components/sections/Home/ContactUsSection'
import CompareBrokerSection from '../components/sections/Home/CompareBrokerSection'
import NewsSection from '../components/sections/Home/NewsSection'
import TickerTape from '../components/TickerTape'
import { TypeAnimation } from 'react-type-animation';
import { motion } from 'framer-motion';
import TradingNewsCards from '../components/TradingNewsCards'

const Home = () => {
  return (
    <>
      <div className='bg-black'>
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        >
        <div className='pt-[90px] bg-[black] fixed w-full px-6 z-10 3xl:px-[100px]'>
          <TickerTape />
        </div>
        <div className='headerbg md:pt-48 px-5 3xl:px-[100px]'>
          <div className='flex justify-start items-center w-full h-full'>
            <div className='text-[white] flex-col pt-20 md:pt-10 gap-y-10 md:gap-y-8 md:w-[60%]'>
              <div>
                <h2 className='text-[30px] md:text-[54px] font-bold'>
                  Welcome to <span style={{borderBottom:'2px solid #FDAD00'}}>MarketsMind.ai </span>
                </h2>
              </div>
              <div className='h-[100px] pt-5 md:pt-0 md:h-[initial]'>
                <TypeAnimation
                  sequence={['Get Real-Time Market Magic From AI', 500, 'Be In The Know, Like A True Insider', 500, 'Find Your Perfect Broker', 500,]}
                  style={{ fontSize: '1.5em', fontWeight: '700' }}
                  repeat={Infinity}
                />
              </div>
            </div>
          </div>
          <div className='pt-28 pb-10 hidden md:flex justify-end pr-2'>
            <div className='flex items-center gap-x-1 bg-gray-200 bg-opacity-25 w-1/5 rounded-full justify-center'>
              <img src={aichat} alt='ai chat icon' />
              <p className='text-[#0E9F6E] font-semibold'>Ask me anything</p>
            </div>
          </div>
        </div>
        <div className='px-5 md:px-20 pt-10 3xl:px-0 bg-[black] text-white w-full'>
          <TradingNewsCards />
        </div>
        <div className='px-5 md:px-20 3xl:px-0 pb-10 bg-[black] flex flex-col justify-center items-center'>
          <NewsSection />
        </div>
        <div className='px-5 md:px-20 3xl:px-0 py-10 bg-[black] flex flex-col justify-center items-center text-white'>
          <CompareBrokerSection />
        </div>
        <div className='px-5 md:px-20 3xl:px-0 py-10 py-10 bg-[black] flex flex-col justify-center items-center text-white'>
          <ContactUsSection />
        </div>
      </motion.div>
      </div>
    </>
  )
}

export default Home