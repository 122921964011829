import React from 'react';

class TickerTape extends React.Component {
  componentDidMount() {
    // Check if the TradingView script has already been added to the page
    if (document.querySelector('.ticker-tape-container script')) {
      return;
    }

    // Create a new script element
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js';
    script.async = true;

    // Set the script content
    script.innerHTML = JSON.stringify({
      symbols: [
        {
          proName: 'FOREXCOM:SPXUSD',
          title: 'S&P 500',
        },
        {
          proName: 'FOREXCOM:NSXUSD',
          title: 'US 100',
        },
        {
          proName: 'FX_IDC:EURUSD',
          title: 'EUR to USD',
        },
        {
          proName: 'BITSTAMP:BTCUSD',
          title: 'Bitcoin',
        },
        {
          proName: 'BITSTAMP:ETHUSD',
          title: 'Ethereum',
        },
        {
          description: 'Gold',
          proName: 'TVC:GOLD',
        },
        {
          description: 'TSLA',
          proName: 'NASDAQ:TSLA',
        },
      ],
      showSymbolLogo: true,
      colorTheme: 'dark',
      isTransparent: true,
      displayMode: 'adaptive',
      locale: 'en',
    });

    // Add the script element to the page
    document.querySelector('.ticker-tape-container').appendChild(script);

    // Create a new MutationObserver
    const observer = new MutationObserver((mutations) => {
      // Loop over the mutations
      for (const mutation of mutations) {
        // Check if nodes were added
        if (mutation.addedNodes.length > 0) {
          // Find the header element
          const header = document.querySelector('.tv-header__link');

          // Check if the header element was found
          if (header) {
            // Remove the header element from the page
            header.remove();
          }
        }
      }
    });

    // Start observing changes to the DOM
    observer.observe(document.body, { childList: true, subtree: true });

    // Add an event listener to intercept clicks on the TickerTape widget
    document.querySelector('.ticker-tape-container').addEventListener('click', (event) => {
      event.preventDefault();
    });
  }

  render() {
    return (
      <div className="tradingview-widget-container">
        <div className="ticker-tape-container"></div>
        <div className="tradingview-widget-copyright">
          <a href="https://www.tradingview.com/" rel="noopener noreferrer" target="_blank">
            {/* <span className="blue-text">Track all markets on TradingView</span> */}
          </a>
        </div>
      </div>
    );
  }
}

export default TickerTape;
