import React, { useState } from 'react'
import '../App.css'
import { Link, useNavigate } from 'react-router-dom'
import { motion } from "framer-motion"
import axios from 'axios'
import { useAuth } from '../AuthContext';

import logo from '../assets/images/marketsmind_logo.svg'
import mobileLogo from '../assets/images/mobile_logo.svg'
import menuBArIcon from '../assets/images/menu_bar.svg'
import { useScrollContext } from '../ScrollContext';

const GreenNavigations = () => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const { isLoggedIn, setIsLoggedIn } = useAuth();
  const { scrollToEconCalender } = useScrollContext();
  const handleOpen = () => {
    setOpen(!open)
  }
  const handleLogout = async () => {
    try {
      // Get the token from localStorage
      const token = localStorage.getItem('token');
  
      // If the token is not found in localStorage, display an error message
      if (!token) {
        console.log('Token not found. Please log in again.');
        return;
      } else {
        console.log(`Got token ${token}`);
      }
  
      // Replace 'YOUR_API_URL_HERE' with the actual URL for your logout API
      const apiUrl = 'https://mm-authentications-fdbd21bae0cc.herokuapp.com/api/logout/';
  
      // Set the Authorization header with the token
      const config = {
        headers: {
          'Authorization': `Token ${token}`,
        }
      };
  
      // Send the POST request to log out
      const response = await axios.post(apiUrl, null, config);
  
      // Check the status code and update the state accordingly
      if (response.status === 200 || response.status === 204 || response.status === 205) {
        // Remove token from localStorage after successful logout
        localStorage.removeItem('token');
        console.log('removed token');
        console.log('Logout successful');
        setIsLoggedIn(false)
        navigate('/')
      } else {
        console.log('Logout failed with status:', response.status);
        console.log('Logout failed');
      }
    } catch (error) {
      if (error.response) {
        console.error('Error during logout:', error.response.data);
        console.log('Status code:', error.response.status);
        console.log('Logout failed');
      } else {
        console.error('Error during logout:', error.message);
        console.log('Logout failed');
      }
    }
  };
  return (
    <>
      <div className="z-0 md:z-30 greennavbg bg-[black] fixed top-0 left-0 w-full flex justify-between items-center py-4 md:py-0 px-5 backdrop-filter backdrop-blur">
        <div>
          <Link to={'/'}><img src={logo} alt='logo' className='hidden md:block' /></Link>
        </div>
        <ul className='hidden md:flex flex-col md:flex-row gap-x-8 text-[white] navigations'>
          <li><Link to={'/brokers'}>Brokers</Link></li>
          <li className="relative group">
            <Link to={'/news'}>News</Link>
            <ul className="absolute hidden group-hover:block bg-black text-white py-2 w-[200px] rounded-md">
              <li className="px-4 py-2">
                <Link to={'/news'}>Market News</Link>
              </li>
              <li className="px-4 py-2 cursor-pointer" onClick={scrollToEconCalender}>
                Economic Calendar
              </li>
            </ul>
          </li>
          <li><Link to={'/ai-analyst'}>AI analyst</Link></li>
          <li><Link to={'/about-us'}>About us</Link></li>
          <li><Link to={'/contact'}>Contact us</Link></li>
          {isLoggedIn ? <><li className='bg-primaryYellow rounded-full px-4' onClick={handleLogout}><Link to={'/'}>Logout</Link></li>
          </> : <><li className='bg-primaryYellow rounded-full px-4'><Link to={'/sign-up'}>Sign up</Link></li>
            <li><Link to={'/login'}>Login</Link></li></>}
        </ul>
      </div>
      <div className='z-30 md:z-0 py-4 md:py-0 px-5 md:px-20 bg-black bg-opacity-25 backdrop-filter backdrop-blur-xl navbg fixed top-0 left-0 w-full block md:hidden duration-700'>
        <div className='flex justify-between items-center '>
          <Link to={'/'} onClick={() => setOpen(false)}><img src={mobileLogo} alt='logo' className='block md:hidden' /></Link>
          <div className='block md:hidden' onClick={handleOpen}>
            <img src={menuBArIcon} alt='menu_bar_icon' />
          </div>
        </div>
        {
          open &&
          <motion.ul
            initial="closed"
            animate="open"
            variants={{
              open: { opacity: 1, height: 'auto', rotateX: 0 },
              closed: { opacity: 0, height: 0, rotateX: -90 },
            }}
            transition={{ duration: 0.3 }}
            className='text-[white] flex flex-col justify-center items-center mobile-naviagtions'>
            <li onClick={() => setOpen(false)}><Link to={'/brokers'}>Brokers</Link></li>
            <li onClick={() => setOpen(false)}><Link to={'/news'}>News</Link></li>
            <li onClick={() => setOpen(false)}><Link to={'/ai-analyst'}>AI analyst</Link></li>
            <li onClick={() => setOpen(false)}><Link to={'/about-us'}>About us</Link></li>
            <li onClick={() => setOpen(false)}><Link to={'/contact'}>Contact us</Link></li>
            <li onClick={() => setOpen(false)}><Link to={'/sign-up'}>Sign up</Link></li>
            <li onClick={() => setOpen(false)}><Link to={'/login'}>Login</Link></li>
          </motion.ul>
        }
      </div>
    </>
  )
}

export default GreenNavigations