// ScrollContext.js
import React, { createContext, useContext } from 'react';

const ScrollContext = createContext();

export const useScrollContext = () => {
  return useContext(ScrollContext);
};

const ScrollContextProvider = ({ children }) => {
  const scrollToEconCalender = () => {
    const econCalenderElement = document.getElementById('econs');
    if (econCalenderElement) {
      econCalenderElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <ScrollContext.Provider value={{ scrollToEconCalender }}>
      {children}
    </ScrollContext.Provider>
  );
};

export default ScrollContextProvider;
