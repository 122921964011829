import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios'; // You can use Axios to make HTTP requests

import aiimg from '../assets/images/aiimg.svg';
import light from '../assets/images/Light.svg';
import sent from '../assets/images/Sent.svg';

import { motion } from 'framer-motion'

const AiAnalyst = () => {
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([{ "type": "bot", "text": "Hello, how can i help you today?" }]); // To store the chat conversation
  const [isLoading, setIsLoading] = useState(false); // To track the loading state

  const chatHistoryRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom of the chat history container whenever a new message is added
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Input value:', message);

    setIsLoading(true); // Set the loading state to true

    // Update the chat history with the new message
    setChatHistory((prevHistory) => [
      ...prevHistory,
      { type: 'user', text: message },
    ]);

    setMessage(''); // Clear the input after updating the chat history
    chatHistoryRef.current.scrollIntoView({ behavior: "smooth", block: "end" })

    // Make a POST request to the backend API with the user's message
    try {
      const response = await axios.post('https://mm-finai-b6d274e07c92.herokuapp.com/api/ai-analyst/', { message });
      const botResponse = response.data.answer;


      console.log(`Chat History: ${JSON.stringify(chatHistory)}`)
      // Update the chat history with the bot response
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { type: 'bot', text: botResponse },
      ]);
    } catch (error) {
      console.error('Error fetching response:', error);
    } finally {
      setIsLoading(false); // Set the loading state to false
    }
  };



  return (
    <>
      <div className='bg-black h-[100vh]'>
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
        >
          <div className='pt-32 bg-black'>
            <div className='flex flex-col justify-center'>
              <div className={`flex flex-col justify-center items-center gap-y-4 md:gap-y-8 text-center px-10 md:px-0 ${chatHistory.length != 0 && 'hidden'}`}>
                <img src={aiimg} alt='ai_image' />
                <p className='text-[#767676] font-bold text-3xl md:text-[50px]'>
                  Welcome to marketmind.ai
                </p>
                <p className='text-[#767676] font-bold text-2xl md:text-[50px]'>
                  Ai analyst
                </p>
                <div className='flex items-center'>
                  <img src={light} alt='light_img' />
                  <p className='text-[#767676]'>Examples</p>
                </div>
              </div>
              <div className={`flex flex-col md:flex-row px-10 md:px-0 gap-y-6 md:gap-y-0 justify-center gap-x-10 py-20 md:py-12 ${chatHistory.length !== 0 && 'hidden'}`}>
                <div className='bg-[#E6FFF7] border border-[#0E9F6E] flex justify-start items-center md:w-[20%] min-h-[100px] p-4 rounded-xl'>
                  <p>When is the Forex Market Open for Trading?</p>
                </div>
                <div className='bg-[#E6FFF7] border border-[#0E9F6E] flex justify-start items-center md:w-[20%] min-h-[100px] p-4 rounded-xl'>
                  <p>What currencies are included in the Forex Card?</p>
                </div>
                <div className='bg-[#E6FFF7] border border-[#0E9F6E] flex justify-start items-center md:w-[20%] min-h-[100px] p-4 rounded-xl'>
                  <p>What is a PIP?</p>
                </div>
              </div>
              <div className='flex flex-col justify-center pt-2 md:pt-8 px-5 md:px-20'>
                {/* Chat history */}
                <div className={` h-[80vh] md:h-[70vh] overflow-y-auto mb-[10vh] fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-[90%] md:w-[80%] ${chatHistory.length === 0 && 'hidden'}`}>
                  <div className=" w-full flex flex-col absolute max-h-[80vh] overflow-y-auto bottom-0">
                    {chatHistory.map((chat, index) => (
                      <motion.div key={index} initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: 0 }}>
                        <div className={`flex ${chat.type === 'user' ? 'justify-end' : 'justify-start'} w-full`}>
                          <div className={`bg-${chat.type === 'user' ? 'blue' : 'gray'}-200 p-2 rounded-md my-2`} style={{ backgroundColor: chat.type === 'user' ? '#E6FEF6' : '#0E9F6E' }}>
                            {chat.text}
                            <div ref={chatHistoryRef}></div>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </div>

                </div>

                {/* Chat input */}
                <div className='flex justify-center'>
                  <form onSubmit={handleSubmit} className='w-full md:w-[80%] pb-4 fixed bottom-0'>
                    <div className='bg-[#E6FFF7] border border-[#0E9F6E] rounded-full flex min-h-[50px] items-center px-4 gap-x-3'>
                      <input
                        type='text'
                        value={message}
                        onChange={handleChange}
                        className='w-full min-h-[50px] bg-transparent px-4 outline-none'
                        disabled={isLoading}
                      />
                      <button type='submit' disabled={isLoading}>
                        <img
                          src={sent}
                          alt='sent_icon'
                          className='h-[30px]'
                        />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default AiAnalyst;
