import React from 'react'
import Navigations from '../Navigations'
import ChatFooter from '../ChatFooter'

const ChatLayout = ({ children }) => {
    return (
        <>
            <div>
                <Navigations />
                <main>
                    {children}
                </main>
                {/* <ChatFooter /> */}
            </div>
        </>
    )
}

export default ChatLayout