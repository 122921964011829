import React, { useState } from 'react';
import '../App.css'
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { useAuth } from '../AuthContext';

import { AuthButton, AuthSocialButton, YellowButton } from './Buttons';

import facebook from '../assets/images/Facebookicon.svg'
import google from '../assets/images/Google.svg'


export const ContactForm = () => {
  return (
    <Formik
      initialValues={{ name: '', email: '', message: '' }}
      validationSchema={Yup.object({
        name: Yup.string()
          .max(15, 'Must be 15 characters or less')
          .required('Fill this field'),
        email: Yup.string().email('Invalid email address').required('Fill this field'),
        message: Yup.string().required('Fill this field')
      })}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {formik => (
        <Form className='w-full md:w-[75%] my-4 flex flex-col gap-y-6'>
          <div>
            <div className='inputimgclass rounded-md'>
              <Field name="name" type="text" className='py-4 w-full bg-transparent placeholder:text-white text-xs pl-2 rounded-md' placeholder='Enter your name' />
            </div>
            {formik.touched.name && formik.errors.name && (
              <div className='text-[red] text-sm'>{formik.errors.name}</div>
            )}
          </div>

          <div>
            <div className='inputimgclass rounded-md'>
              <Field name="email" type="email" className='py-4 w-full bg-transparent placeholder:text-white text-xs pl-2 rounded-md' placeholder='Enter your email' />
            </div>
            {formik.touched.email && formik.errors.email && (
              <div className='text-[red] text-sm'>{formik.errors.email}</div>
            )}
          </div>

          <div>
            <div className='inputimgclass rounded-md'>
              <Field name="message" as='textarea' type="text" className='py-4 w-full bg-transparent placeholder:text-white text-xs pl-2' placeholder='How can we help you?' style={{ minHeight: '200px' }} />
            </div>
            {formik.touched.message && formik.errors.message && (
              <div className='text-[red] text-sm'>{formik.errors.message}</div>
            )}
          </div>

          <div>
            <YellowButton type='submit' className={'w-full md:w-[20%] z-10 text-white '} buttonText={'Send'} />
          </div>
        </Form>
      )}
    </Formik>
  );
};


export const LoginForm = () => {
  const navigate = useNavigate()
  const [loginError, setLoginError] = useState('');
  const { setIsLoggedIn } = useAuth();
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      // Make the API request here
      const response = await axios.post('https://mm-authentications-fdbd21bae0cc.herokuapp.com/api/login/', values);
      const { token } = response.data;
      localStorage.setItem('token', token);
      // Handle the API response as needed (e.g., storing tokens, updating UI)
      console.log('Login successful!', response.data);
      setIsLoggedIn(true);
      navigate('/')
    } catch (error) {
      // Handle API error (e.g., displaying error message, resetting form)
      console.error('Login failed.', error);
      if (error.response) {
        const { data, status } = error.response;
        if (status === 401) {
          // Unauthorized - Incorrect credentials
          setLoginError('Invalid email or password. Please try again.');
        } else {
          // Other API errors
          setLoginError('An error occurred during login. Please try again later.');
        }
      } else {
        // Network or client-side errors
        setLoginError('An error occurred during login. Please check your internet connection.');
      }
    } finally {
      // Always setSubmitting to false to enable the submit button
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ email: '', password: '', rememberMe: false }}
      validationSchema={Yup.object({
        email: Yup.string().email('Invalid email address').required('Fill this field'),
        password: Yup.string()
          .min(8, 'Password must be at least 8 characters')
          .required('Required'),
      })}
      onSubmit={handleSubmit}
    >
      {formik => (
        <Form className='w-full my-4 flex flex-col gap-y-6'>
          <div>
            <Field name="email" type="text" className='py-4 w-full bg-white placeholder:text-[#333333] text-xs pl-5 rounded-full' placeholder='Email' />
            {formik.touched.email && formik.errors.email && (
              <div className='text-[red] text-sm'>{formik.errors.email}</div>
            )}
          </div>

          <div>
            <Field name="password" type="password" className='py-4 w-full bg-white placeholder:text-black text-xs pl-5 rounded-full' placeholder='Password' />
            {formik.touched.password && formik.errors.password && (
              <div className='text-[red] text-sm'>{formik.errors.password}</div>
            )}
          </div>

          <div className='flex items-center justify-between'>
            <div className='flex items-center'>
              <Field
                type='checkbox'
                name='rememberMe'
                id='rememberMe'
                className='mr-2'
              />
              <label htmlFor='rememberMe' className='text-black text-xs'>Remember me</label>
            </div>
            <Link to={'/forgot-password'} className='text-black text-xs underline'>Forgot password?</Link>
          </div>

          {loginError && (
            <div className='text-red-500 text-sm'>{loginError}</div>
          )}

          <AuthButton type='submit' className={'px-10 z-10'} buttonText={'LOG IN'} />

          {/* <AuthSocialButton buttonText={
            <div className='flex justify-center items-center gap-x-2'>
              <img src={facebook} alt='facebook_icon' />
              <p className='text-black'>Log in with Facebook</p>
            </div>
          } /> */}

          <AuthSocialButton buttonText={
            <div className='flex justify-center items-center gap-x-2'>
              <img src={google} alt='google_icon' />
              <p className='text-black'>Log in with Google</p>
            </div>
          } />

        </Form>
      )}
    </Formik>
  );
};


export const SignUpForm = () => {
  const navigate = useNavigate()
  const [signupError, setSignupError] = useState('');
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      // Make the API request here
      const response = await axios.post('https://mm-authentications-fdbd21bae0cc.herokuapp.com/api/register/', values);

      // Handle the API response as needed (e.g., displaying a success message, redirecting)
      console.log('Signup successful!', response.data);

      navigate('/')
    } catch (error) {
      // Handle API error (e.g., displaying an error message, resetting form)
      console.error('Signup failed.', error);
      if (error.response) {
        const { data, status } = error.response;
        if (status === 400) {
          // Bad Request - Display error message received from the server
          if (data.message === 'USER_ALREADY_EXISTS') {
            setSignupError('An account with this email already exists.');
          } else {
            setSignupError(data.message || 'An error occurred during signup. Please try again.');
          }
        } else {
          // Other API errors
          setSignupError('An error occurred during signup. Please try again later.');
        }
      } else {
        // Network or client-side errors
        setSignupError('An error occurred during signup. Please check your internet connection.');
      }
    } finally {
      // Always setSubmitting to false to enable the submit button
      setSubmitting(false);
    }

  };
  return (
    <Formik
      initialValues={{ username: '', email: '', password: '', confirm_password: '', acceptedterms: false }}
      validationSchema={Yup.object({
        email: Yup.string().email('Invalid email address').required('Fill this field'),
        password: Yup.string()
          .min(8, 'Password must be at least 8 characters')
          .required('Required'),
        confirm_password: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords don\'t match')
          .required('Required'),
      })}
      onSubmit={handleSubmit}
    >
      {formik => (
        <Form className='w-full my-4 flex flex-col gap-y-6'>
          <div>
            <Field name="username" type="text" className='py-4 w-full bg-white placeholder:text-[#333333] text-xs pl-5 rounded-full' placeholder='Name' />
            {formik.touched.username && formik.errors.username && (
              <div className='text-[red] text-sm'>{formik.errors.username}</div>
            )}
          </div>

          <div>
            <Field name="email" type="text" className='py-4 w-full bg-white placeholder:text-[#333333] text-xs pl-5 rounded-full' placeholder='Email' />
            {formik.touched.email && formik.errors.email && (
              <div className='text-[red] text-sm'>{formik.errors.email}</div>
            )}
          </div>

          <div>
            <Field name="password" type="password" className='py-4 w-full bg-white placeholder:text-black text-xs pl-5 rounded-full' placeholder='Password' />
            {formik.touched.password && formik.errors.password && (
              <div className='text-[red] text-sm'>{formik.errors.password}</div>
            )}
          </div>

          <div>
            <Field name="confirm_password" type="password" className='py-4 w-full bg-white placeholder:text-black text-xs pl-5 rounded-full' placeholder='Confirm Password' />
            {formik.touched.confirm_password && formik.errors.confirm_password && (
              <div className='text-[red] text-sm'>{formik.errors.confirm_password}</div>
            )}
          </div>

          <div className='flex items-center justify-between'>
            <div className='flex items-center'>
              <Field
                type='checkbox'
                name='acceptedterms'
                id='acceptedterms'
                className='mr-2'
              />
              <label htmlFor='acceptedterms' className='text-black text-xs'>I agree to and accept the General Terms and Conditions</label>
            </div>
          </div>

          {signupError && (
            <div className='text-red-500 text-sm'>{signupError}</div>
          )}

          <AuthButton type='submit' className={'px-10 z-10'} buttonText={'sign up'} />

          <AuthSocialButton buttonText={
            <div className='flex justify-center items-center gap-x-2'>
              <img src={facebook} alt='facebook_icon' />
              <p className='text-black'>Continue with Facebook</p>
            </div>
          } />

          <AuthSocialButton buttonText={
            <div className='flex justify-center items-center gap-x-2'>
              <img src={google} alt='google_icon' />
              <p className='text-black'>Continue with Google</p>
            </div>
          } />

        </Form>
      )}
    </Formik>
  );
};

export const ForgotPasswordForm = () => {
  return (
    <Formik
      initialValues={{ email: '', }}
      validationSchema={Yup.object({
        email: Yup.string().email('Invalid email address').required('Fill this field'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {formik => (
        <Form className='w-full my-4 flex flex-col gap-y-6'>
          <div>
            <Field name="email" type="text" className='py-4 w-full bg-white placeholder:text-[#333333] text-xs pl-5 rounded-full' placeholder='Email' />
            {formik.touched.email && formik.errors.email && (
              <div className='text-[red] text-sm'>{formik.errors.email}</div>
            )}
          </div>


          <AuthButton type='submit' className={'px-10 z-10 font-semibold'} buttonText={'send password reset link'} />

        </Form>
      )}
    </Formik>
  );
};

export const ResetPasswordForm = () => {
  return (
    <Formik
      initialValues={{ password: '', confirm_password: '' }}
      validationSchema={Yup.object({
        password: Yup.string()
          .min(8, 'Password must be at least 8 characters')
          .required('Required'),
        confirm_password: Yup.string()
          .oneOf([Yup.ref('password'), null], 'Passwords don\'t match')
          .required('Required'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => {
          alert(JSON.stringify(values, null, 2));
          setSubmitting(false);
        }, 400);
      }}
    >
      {formik => (
        <Form className='w-full my-4 flex flex-col gap-y-6'>

          <div>
            <Field name="password" type="password" className='py-4 w-full bg-white placeholder:text-black text-xs pl-5 rounded-full' placeholder='Password' />
            {formik.touched.password && formik.errors.password && (
              <div className='text-[red] text-sm'>{formik.errors.password}</div>
            )}
          </div>

          <div>
            <Field name="confirm_password" type="password" className='py-4 w-full bg-white placeholder:text-black text-xs pl-5 rounded-full' placeholder='Confirm Password' />
            {formik.touched.confirm_password && formik.errors.confirm_password && (
              <div className='text-[red] text-sm'>{formik.errors.confirm_password}</div>
            )}
          </div>


          <AuthButton type='submit' className={'px-10 z-10 font-semibold uppercase'} buttonText={'reset passoword'} />


        </Form>
      )}
    </Formik>
  );
};

