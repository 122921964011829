import React from 'react'

export const LiveUpdateButton = () => {
  return (
    <>
        <button className='text-[white] border border-[white] rounded-full px-4 py-1'>
            Read more 
        </button>
    </>
  )
}

export const YellowButton = ({buttonText, type, className}) => {
    return (
      <>
          <button type={type} className={`text-[black] bg-primaryYellow border border-[black] rounded-full px-4 py-3 ${className}`}>
              {buttonText}
          </button>
      </>
    )
  }

  export const AuthButton = ({buttonText, type, className}) => {
    return (
      <>
          <button type={type} className={`text-[white] uppercase rounded-full bg-primaryGreen border border-[white] rounded-full px-4 py-3 ${className}`}>
              {buttonText}
          </button>
      </>
    )
  }

  export const AuthSocialButton = ({buttonText, type, className}) => {
    return (
      <>
          <button type={type} className={`text-[white] rounded-full bg-white border border-[white] px-4 py-3 ${className}`}>
              {buttonText}
          </button>
      </>
    )
  }

