import React from 'react'
import '../../../App.css'
import Navigations from './Navigations'

const Header = () => {
    return (
        <>
            <div className='flex text-center text-white'>
                <div className='news_header text-white py-16 md:py-10'>
                    <div>
                        <h1 className='font-bold text-[60px]'>News</h1>
                        <p className='font-semibold text-xl'>Follow what's happening with real-time updates from around the
                            world.</p>
                    </div>
                    <div className='hidden md:block'>
                        <Navigations />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header