import React from 'react'
import blacklogo from '../assets/images/marketsmind_logo.svg'
// import bars from '../assets/images/bars.png'
import { SignUpForm } from '../components/Forms'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'

const SignUp = () => {
  return (
    <>
      <div className='bg-black'>
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
        >
          <div className='py-20 md:py-32 px-5 md:px-20 flex flex-col md:flex-row gap-x-10 gap-y-10 md:gap-y-0 items-center bg-black'>
            <div className='w-full md:w-[50%]'>
              <div><img src={blacklogo} alt='black_logo' className='hidden md:block' /></div>
              <div className='flex flex-col gap-y-5 md:fap-y-0 text-white'>
                <p className='font-bold text-4xl md:text-5xl whitespace-normal break-words'>Welcome to Marketsmind.ai</p>
                <p>Join our community of savvy investors today and find <br />the perfect broker for your needs!</p>
              </div>
            </div>
            <div className='w-full md:w-[40%]'>
              <div className='bg-[#E6FEF6] rounded-lg border border-[#0E9F6E] p-5'>
                <SignUpForm />
                <p className='text-center text-black'>Already have an account? <Link to={'/login'}><span className='font-bold'>Log in</span></Link></p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  )
}

export default SignUp